import type { App } from 'vue'

// 需要全局引入一些组件，如ElScrollbar，不然一些下拉项样式有问题
import Ele from 'element-plus'
import('element-plus/dist/index.css')
//
// const plugins = [ElLoading]
//
// const components = [ElScrollbar]

export const setupElementPlus = (app: App<Element>) => {
  // plugins.forEach((plugin) => {
  //   app.use(plugin)
  // })
  //
  // // 为了开发环境启动更快，一次性引入所有样式
  // if (import.meta.env.VITE_USE_ALL_ELEMENT_PLUS_STYLE === 'true') {
  //   import('element-plus/dist/index.css')
  //   return
  // }
  //
  // components.forEach((component) => {
  //   app.component(component.name!, component)
  // })
  app.use(Ele)
}
