import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import ContentLayout from '@/layout/ContentLayout.vue'
const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Root',
    meta: {
      hidden: true,
      isRoot: true
    }
  },
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Dashboard',
    meta: {
      title: t('router.dashboard'),
      icon: 'carbon:home',
      alwaysShow: true,
      isRoot: true
    },
    children: [
      {
        path: 'analysis',
        component: () => import('@/views/Dashboard/Analysis.vue'),
        name: 'Analysis',
        meta: {
          title: t('router.analysis'),
          noCache: true,
          affix: true,
          icon: 'carbon:text-link-analysis'
        }
      }
    ]
  },
  {
    path: '/mall',
    component: Layout,
    name: 'mall',
    meta: {
      hidden: false,
      title: '配件商城',
      icon: 'carbon:shopping-cart',
      isRoot: true
    },
    children: [
      {
        path: 'mallSetting',
        component: ContentLayout,
        name: 'mallSettingIndex',
        meta: {
          title: '商城设置',
          icon: 'carbon:presentation-file'
        },
        children: [
          {
            path: 'mallSettingIndex',
            name: 'mallSettingIndex',
            component: () => import('@/views/mall/mallSetting/mallSetting.vue'),
            meta: {
              title: '商城设置',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'businessSetting',
            name: 'businessSetting',
            component: () => import('@/views/mall/mallSetting/businessSetting.vue'),
            meta: {
              title: '交易设置',
              icon: 'carbon:presentation-file'
            }
          }
        ]
      },
      {
        path: 'goods',
        name: 'goods',
        component: () => import('@/views/mall/goods/index.vue'),
        meta: {
          title: '商品管理(总后台)',
          icon: 'carbon:product'
        }
      },
      {
        path: 'goodsSeller',
        name: 'goodsSeller',
        component: () => import('@/views/mall/goodsSeller/index.vue'),
        meta: {
          title: '商品管理(商家)',
          icon: 'carbon:product'
        }
      },
      {
        path: 'goodsCate',
        name: 'goodsCate',
        component: () => import('@/views/mall/goodsCate/index.vue'),
        meta: {
          title: '商品分类',
          icon: 'carbon:product'
        }
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import('@/views/mall/coupon/index.vue'),
        meta: {
          title: '优惠券',
          icon: 'carbon:product'
        }
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/mall/order/index.vue'),
        meta: {
          title: '订单管理',
          icon: 'carbon:order-details'
        }
      },
      {
        path: 'marketing',
        name: 'marketing',
        component: () => import('@/views/mall/marketing/index.vue'),
        meta: {
          title: '营销中心',
          icon: 'carbon:piggy-bank'
        }
      },
      {
        path: 'financing',
        name: 'financing',
        component: () => import('@/views/mall/financing/index.vue'),
        meta: {
          title: '财务管理',
          icon: 'carbon:money'
        }
      },
      {
        path: 'delivery',
        name: 'delivery',
        component: () => import('@/views/mall/delivery/index.vue'),
        meta: {
          title: '运费模板管理',
          icon: 'carbon:money'
        }
      },
      {
        path: 'platformOrder',
        name: 'platformOrder',
        component: ContentLayout,
        meta: {
          title: '平台订单管理',
          icon: 'carbon:money'
        },
        children: [
          {
            path: '',
            name: 'platformOrderIndex',
            component: () => import('@/views/mall/platformOrder/index.vue'),
            meta: {
              title: '全部订单',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder20',
            name: 'platformOrder20',
            component: () => import('@/views/mall/platformOrder/order20.vue'),
            meta: {
              title: '待付款',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder30',
            name: 'platformOrder30',
            component: () => import('@/views/mall/platformOrder/order30.vue'),
            meta: {
              title: '待发货',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder40',
            name: 'platformOrder40',
            component: () => import('@/views/mall/platformOrder/order40.vue'),
            meta: {
              title: '待收货',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder50',
            name: 'platformOrder50',
            component: () => import('@/views/mall/platformOrder/order50.vue'),
            meta: {
              title: '待评价',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder60',
            name: 'platformOrder60',
            component: () => import('@/views/mall/platformOrder/order60.vue'),
            meta: {
              title: '售后',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder70',
            name: 'platformOrder70',
            component: () => import('@/views/mall/platformOrder/order70.vue'),
            meta: {
              title: '取消',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder80',
            name: 'platformOrder80',
            component: () => import('@/views/mall/platformOrder/order80.vue'),
            meta: {
              title: '待审核(已微信支付)',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'platformOrder90',
            name: 'platformOrder90',
            component: () => import('@/views/mall/platformOrder/order90.vue'),
            meta: {
              title: '待审核(线下支付)',
              icon: 'carbon:presentation-file'
            }
          }
        ]
      },
      {
        path: 'businessTag',
        name: 'businessTag',
        component: () => import('@/views/mall/businessTag/index.vue'),
        meta: {
          title: '仓库标签管理',
          icon: 'carbon:money'
        }
      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import('@/views/mall/brands/index.vue'),
        meta: {
          title: '品牌管理(总后台)',
          icon: 'carbon:money'
        }
      },
      {
        path: 'brandSeller',
        name: 'brandSeller',
        component: () => import('@/views/mall/brandSeller/index.vue'),
        meta: {
          title: '品牌管理(商家)',
          icon: 'carbon:money'
        }
      },
      {
        path: 'business',
        name: 'business',
        component: () => import('@/views/mall/business/index.vue'),
        meta: {
          title: '仓库管理',
          icon: 'carbon:money'
        }
      },
      {
        path: 'serviceGuarantee',
        name: 'serviceGuarantee',
        component: () => import('@/views/mall/serviceGuarantee/index.vue'),
        meta: {
          title: '服务保障',
          icon: 'carbon:money'
        }
      },
      {
        path: 'expressSetting',
        component: ContentLayout,
        name: 'expressSettingIndex',
        meta: {
          title: '包邮设置',
          icon: 'carbon:presentation-file'
        },
        children: [
          {
            path: '',
            name: 'expressMasterSetting',
            component: () => import('@/views/mall/expressSetting/masterSetting.vue'),
            meta: {
              title: '师傅包邮设置',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'warehouseSetting',
            name: 'mallWarehouseSetting',
            component: () => import('@/views/mall/expressSetting/warehouseSetting.vue'),
            meta: {
              title: '仓库包邮设置',
              icon: 'carbon:presentation-file'
            }
          }
        ]
      }
    ]
  },
  //权限
  {
    path: '/permission',
    component: Layout,
    redirect: '/permission/role',
    name: 'permission',
    meta: {
      hidden: false,
      title: '系统权限',
      icon: 'carbon:settings',
      isRoot: true
    },
    children: [
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/permission/role/index.vue'),
        meta: {
          title: '角色管理',
          icon: 'carbon:user'
        }
      },
      {
        path: 'integralList',
        name: 'integralList',
        component: () => import('@/views/permission/member/integralList.vue'),
        meta: {
          title: '积分列表',
          icon: 'carbon:user',
          hidden: true
        }
      },
      {
        path: 'member',
        name: 'mallSetting',
        component: () => import('@/views/permission/member/index.vue'),
        meta: {
          title: '会员列表',
          icon: 'carbon:user-access'
        }
      },
      {
        path: 'actionLog',
        name: 'actionLog',
        component: () => import('@/views/permission/actionLog/index.vue'),
        meta: {
          title: '操作日志',
          icon: 'carbon:catalog'
        }
      }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/imageVideoLimit',
    name: 'setting',
    meta: {
      hidden: false,
      title: '综合设置',
      icon: 'carbon:home',
      isRoot: true
    },
    children: [
      {
        path: 'imageVideoLimit',
        name: 'imageVideoLimit',
        component: () => import('@/views/setting/imageLimit/index.vue'),
        meta: {
          title: '图片视频大小设置',
          icon: 'carbon:home'
        }
      },
      {
        path: 'fault',
        name: 'fault',
        component: () => import('@/views/setting/fault/index.vue'),
        meta: {
          title: '故障查询设置',
          icon: 'carbon:home'
        }
      },
      {
        path: 'settingDelivery',
        name: 'settingDelivery',
        component: () => import('@/views/setting/delivery/index.vue'),
        meta: {
          title: '快递列表',
          icon: 'carbon:home'
        }
      },
      {
        path: 'settingDeliveryConfig',
        name: 'settingDeliveryConfig',
        component: () => import('@/views/setting/delivery/config.vue'),
        meta: {
          title: '快递配置',
          icon: 'carbon:home'
        }
      },
      {
        path: 'settingDeliveryQuick',
        name: 'settingDeliveryQuick',
        component: () => import('@/views/setting/delivery/quick.vue'),
        meta: {
          title: '快递快捷标签列表',
          icon: 'carbon:home'
        }
      },
      {
        path: 'agreement',
        component: ContentLayout,
        name: 'agreement',
        meta: {
          title: '协议管理',
          icon: 'carbon:presentation-file'
        },
        children: [
          //service_agreement    服务协议  ,privacy_agreement 隐私协议   ,business_agreement 商家入驻协议
          {
            path: '',
            name: 'serviceAgreement',
            component: () => import('@/views/setting/agreements/service.vue'),
            meta: {
              title: '服务协议',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'privacy',
            name: 'privacyAgreement',
            component: () => import('@/views/setting/agreements/privacy.vue'),
            meta: {
              title: '隐私协议',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'business',
            name: 'businessAgreement',
            component: () => import('@/views/setting/agreements/business.vue'),
            meta: {
              title: '商家入驻协议',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'release',
            name: 'releaseAgreement',
            component: () => import('@/views/setting/agreements/release.vue'),
            meta: {
              title: '发布供应协议',
              icon: 'carbon:presentation-file'
            }
          }
        ]
      },
      {
        path: 'notice',
        component: ContentLayout,
        name: 'notice',
        meta: {
          title: '公告管理',
          icon: 'carbon:presentation-file'
        },
        children: [
          //1 商品详情 2 仓库后台  3 商城主页  4  仓库主页  5 故障查询  6内部公告
          {
            path: 'goods',
            name: 'goodsNotice',
            component: () => import('@/views/setting/notices/goods.vue'),
            meta: {
              title: '商品详情公告',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'warehouse',
            name: 'warehouseHouse',
            component: () => import('@/views/setting/notices/warehouse.vue'),
            meta: {
              title: '仓库后台公告',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'mallHome',
            name: 'mallHome',
            component: () => import('@/views/setting/notices/mallHome.vue'),
            meta: {
              title: '商城主页公告',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'warehouseHome',
            name: 'warehouseHome',
            component: () => import('@/views/setting/notices/warehouseHome.vue'),
            meta: {
              title: '仓库主页公告',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'faultQuery',
            name: 'faultQueryNotice',
            component: () => import('@/views/setting/notices/faultQuery.vue'),
            meta: {
              title: '故障查询公告',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'innerNotice',
            name: 'innerNotice',
            component: () => import('@/views/setting/notices/innerNotice.vue'),
            meta: {
              title: '内部公告',
              icon: 'carbon:presentation-file'
            }
          }
        ]
      },
      {
        path: 'server',
        name: 'server',
        component: () => import('@/views/setting/server/server.vue'),
        meta: {
          title: '客服管理设置',
          icon: 'carbon:home'
        }
      },
      {
        path: 'storage',
        name: 'storage',
        component: ContentLayout,
        redirect: '/setting/storage/fileList',
        meta: {
          title: '云存储',
          icon: 'carbon:home'
        },
        children: [
          {
            path: 'fileList',
            component: () => import('@/views/setting/yunStorage/index.vue'),
            name: '文件列表',
            meta: {
              title: '文件列表',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'groupList',
            name: '分组列表',
            component: () => import('@/views/setting/yunStorage/group.vue'),
            meta: {
              title: '分组列表',
              icon: 'carbon:presentation-file'
            }
          },
          {
            path: 'settingOss',
            component: () => import('@/views/setting/yunStorage/oss.vue'),
            name: 'settingOss',
            meta: {
              title: 'OSS设置',
              icon: 'carbon:presentation-file'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
  {
    path: '/faultInquiry',
    component: Layout,
    redirect: '/faultInquiry/brand',
    name: 'FaultInquiry',
    meta: {
      title: t('router.faultInquiry'),
      icon: 'carbon:skill-level-advanced',
      isRoot: true
    },
    children: [
      {
        path: 'brand',
        name: 'Brand',
        component: () => import('@/views/FaultInquiry/Brand/Index.vue'),
        meta: {
          title: t('router.brand'),
          icon: 'ic:outline-branding-watermark'
        }
      },
      // {
      //   path: 'trafficmaster',
      //   name: 'TrafficMaster',
      //   component: () => import('@/views/FaultInquiry/TrafficMaster/Index.vue'),
      //   meta: {
      //     title: t('router.trafficmaster')
      //   }
      // },
      // {
      //   path: 'codeclassification',
      //   name: 'CodeClassification',
      //   component: () => import('@/views/FaultInquiry/CodeClassification/Index.vue'),
      //   meta: {
      //     title: t('router.codeClassification')
      //   }
      // },
      {
        path: 'querycode',
        name: 'QueryCode',
        component: () => import('@/views/FaultInquiry/QueryCode/Index.vue'),
        meta: {
          title: t('router.queryCode'),
          icon: 'carbon:query'
        }
      },
      {
        path: 'datasharingclass',
        name: 'DataSharingClass',
        component: () => import('@/views/FaultInquiry/DataSharingClass/Index.vue'),
        meta: {
          title: t('router.dataSharingClass'),
          icon: 'ic:twotone-query-stats'
        }
      },
      {
        path: 'datasharing',
        name: 'DataSharing',
        component: () => import('@/views/FaultInquiry/DataSharing/Index.vue'),
        meta: {
          title: t('router.dataSharing'),
          icon: 'carbon:presentation-file'
        }
      },
      {
        path: 'carouselchart',
        name: 'CarouselChart',
        component: () => import('@/views/FaultInquiry/CarouselChart/Index.vue'),
        meta: {
          title: t('router.carouselChart'),
          icon: 'lucide:image-play'
        }
      },
      {
        path: 'journal',
        name: 'Journal',
        component: () => import('@/views/FaultInquiry/Journal/Index.vue'),
        meta: {
          title: t('router.journal'),
          icon: 'carbon:ibm-watson-knowledge-catalog'
        }
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: ContentLayout,
        meta: {
          title: '用户反馈',
          icon: 'carbon:ibm-watson-knowledge-catalog'
        },
        children: [
          {
            path: 'dataFeedBack',
            name: 'dataFeedBack',
            component: () => import('@/views/FaultInquiry/Feedback/DataFeedback.vue'),
            meta: {
              title: '资料分享反馈',
              icon: 'carbon:ibm-watson-knowledge-catalog'
            }
          },
          {
            path: 'codingFeedback',
            name: 'codingFeedback',
            component: () => import('@/views/FaultInquiry/Feedback/CodeFeedback.vue'),
            meta: {
              title: '查询代码反馈',
              icon: 'carbon:ibm-watson-knowledge-catalog'
            }
          }
        ]
      },
      {
        path: 'dustbinList',
        name: 'dustbinList',
        component: ContentLayout,
        meta: {
          title: '回收站',
          icon: 'carbon:ibm-watson-knowledge-catalog'
        },
        children: [
          {
            path: 'dataDustbinList',
            name: 'dataDustbinList',
            component: () => import('@/views/FaultInquiry/Dustbin/DataShareDustbin.vue'),
            meta: {
              title: '资料分享',
              icon: 'carbon:ibm-watson-knowledge-catalog'
            }
          },
          {
            path: 'codingDustbinList',
            name: 'codingDustbinList',
            component: () => import('@/views/FaultInquiry/Dustbin/QueryCodeDustbin.vue'),
            meta: {
              title: '查询代码',
              icon: 'carbon:ibm-watson-knowledge-catalog'
            }
          }
        ]
      },
      {
        path: 'uploadData',
        name: 'uploadData',
        component: () => import('@/views/FaultInquiry/UploadData/index.vue'),
        meta: {
          title: '上传资料',
          icon: 'carbon:ibm-watson-knowledge-catalog'
        }
      }
    ]
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  // {
  //   path: '/level',
  //   component: Layout,
  //   redirect: '/level/menu1/menu1-1/menu1-1-1',
  //   name: 'Level',
  //   meta: {
  //     title: t('router.level'),
  //     icon: 'carbon:skill-level-advanced'
  //   },
  //   children: [
  //   ]
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
