<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')

const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)

appStore.initTheme()
</script>

<template>
  <ConfigGlobal :size="currentSize">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
  </ConfigGlobal>
</template>

<style lang="less">
@prefix-cls: ~'@{namespace}-app';

.size {
  width: 100%;
  height: 100%;
}

html,
body {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  .size;

  #app {
    .size;
  }
}

.@{prefix-cls}-grey-mode {
  filter: grayscale(100%);
}

.el-input-number .el-input__wrapper {
  padding: 0 !important;
}

.preview-box {
  &.alert {
    position: relative;
    width: 350px;
    height: 520px;
    padding: 20px 5px;
    overflow: auto;
    border: 5px solid #d9d9d9;
    border-radius: 40px;
    box-sizing: border-box;
    scrollbar-width: none;
  }

  img {
    max-width: 100% !important;
  }

  video {
    max-width: 100% !important;
  }
}
</style>
